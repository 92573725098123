import React, { useEffect, useState } from "react";

import {
  engagementTypeListProps,
  getLocationProps,
  optionsProp,
  selectedItemsProps,
} from "../../../../@types/component";

import FilterSection from "./FilterBar";
import OptionalRules from "./optionalRules";
import StandardRules from "./standardRules";

import classes from "./rulesTable.module.scss";

interface RulesTableProps {
  selectedClient: optionsProp;
  engagementTypeList: engagementTypeListProps[];
  clearEngagementType: (filterType: string) => void;
  handleFilterChange: (
    filterType: string,
    filterValue: engagementTypeListProps
  ) => void;
  selectedFilters: selectedItemsProps;
  handleReset: () => void;
  selectedDate: Date | null;
  handleDateChange: (val: Date | null) => void;
  handleDateClear: () => void;
  handleFetchRules: () => void;
  receivedStandardRules: ManageStandardRules[] | null;
  receivedOptionalRules: ManageOptionalRules[] | null;
  getOptionalRules: (
    updatedOR: {
      id: number;
      checked: boolean;
    }[]
  ) => void;
  locationData: getLocationProps | null;
  handleRateFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  payRateFilter: number | null;
  writeAccess?: boolean;
}

const RulesTableSection: React.FC<RulesTableProps> = ({
  selectedClient,
  engagementTypeList,
  clearEngagementType,
  selectedFilters,
  handleFilterChange,
  handleReset,
  selectedDate,
  handleDateChange,
  handleDateClear,
  handleFetchRules,
  receivedStandardRules,
  receivedOptionalRules,
  getOptionalRules,
  locationData,
  handleRateFilterChange,
  payRateFilter,
  writeAccess,
}) => {
  const [optoinalRules, setOptionalRules] = useState<
    ManageOptionalRules[] | null
  >(null);

  const [standardRules, setStandardRules] = useState<
    ManageStandardRules[] | null
  >(null);

  useEffect(() => {
    if (receivedOptionalRules) {
      setOptionalRules(receivedOptionalRules);
    }

    if (receivedStandardRules) {
      setStandardRules(receivedStandardRules);
    }
  }, [receivedOptionalRules, receivedStandardRules]);

  return (
    <div className={classes.container} data-testid="rules_table_secion">
      <FilterSection
        selectedClient={selectedClient}
        engagementTypeList={engagementTypeList}
        clearEngagementType={clearEngagementType}
        selectedFilters={selectedFilters}
        handleFilterChange={handleFilterChange}
        handleReset={handleReset}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        handleDateClear={handleDateClear}
        handleFetchRules={handleFetchRules}
        locationData={locationData}
        handleRateFilterChange={handleRateFilterChange}
        payRateFilter={payRateFilter}
      />

      <StandardRules
        rule={{
          data: {
            standardRules:
              standardRules && standardRules.length > 0 ? standardRules : [],
          },
        }}
      />

      <OptionalRules
        rule={{
          data: {
            optionalRules:
              optoinalRules && optoinalRules.length > 0 ? optoinalRules : [],
          },
        }}
        getOptionalRules={getOptionalRules}
        writeAccess={writeAccess}
      />
    </div>
  );
};

export default RulesTableSection;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import { optionsProp, selectedItemsProps } from "../../../../@types/component";
import API from "../../../../API";
import PricingCalcApi from "../../../../API/PricingCalcApi";
import useStore from "../../../../store/useStore";

import SimulateHeader from "./Header";
import SendJD from "./SendJD";
import SimulateContent from "./SimulateContent";

import classes from "./simulation.module.scss";

interface SimulateScreenProps {
  selectedFilters: selectedItemsProps;
  selectedClient: optionsProp;
  receivedStandardRules: ManageStandardRules[] | null;
  receivedOptionalRules: ManageOptionalRules[] | null;
  selectedDate: Date | null;
  simulateDisable: boolean;
  updatedOptionalRules?: {
    id: number;
    checked: boolean;
  }[];
  payRateFilter: number | null;
  buttonClicked: boolean;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedTabIndex: number;
  setPayToBillInput: React.Dispatch<React.SetStateAction<string>>;
  payToBillInput: string;
  setBillToPayInput: React.Dispatch<React.SetStateAction<string>>;
  billToPayInput: string;
  setErrorCode: React.Dispatch<React.SetStateAction<number | null>>;
  errorCode: number | null;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  errorMessage?: string;
  setWarningMsg: React.Dispatch<React.SetStateAction<boolean>>;
  warningMsg: boolean;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
  openJobDiva: boolean;
  setResponseData: React.Dispatch<
    React.SetStateAction<CalcAtributes["data"] | null>
  >;
  responseData: CalcAtributes["data"] | null;
  setSowResponseData: React.Dispatch<
    React.SetStateAction<CalcAtributes["data"] | null>
  >;
  sowResponseData: CalcAtributes["data"] | null;
  writeAccess?: boolean;
  setPayRateFilter: React.Dispatch<React.SetStateAction<number | null>>;
  setRateFilterChange: React.Dispatch<React.SetStateAction<boolean>>;
  rateFilterChange: boolean;
  setRateToSimulate: React.Dispatch<React.SetStateAction<string>>;
  rateToSimulate: string;
}

const SimulateScreen: React.FC<SimulateScreenProps> = ({
  selectedFilters,
  selectedClient,
  receivedStandardRules,
  receivedOptionalRules,
  selectedDate,
  simulateDisable,
  updatedOptionalRules,
  payRateFilter,
  buttonClicked,
  setSelectedTabIndex,
  selectedTabIndex,
  setPayToBillInput,
  payToBillInput,
  setBillToPayInput,
  billToPayInput,
  setErrorCode,
  errorCode,
  setWarningMsg,
  warningMsg,
  setJobDiva,
  openJobDiva,
  setResponseData,
  responseData,
  sowResponseData,
  setSowResponseData,
  writeAccess,
  setPayRateFilter,
  setRateFilterChange,
  rateFilterChange,
  setRateToSimulate,
  rateToSimulate,
  setErrorMessage,
  errorMessage,
}) => {
  const setLoader = useStore((state) => state.setLoader);
  const [payBillChanges, setPayBillChanged] = useState<boolean>(false);
  const [debouncedText] = useDebounce(rateToSimulate, 800);

  useEffect(() => {
    setPayToBillInput("");

    // selectedFilters.deliverableType.name.toLowerCase() !== "sow/milestone" &&
    if (selectedTabIndex === 0) {
      setResponseData(null);
    }
  }, [selectedFilters, selectedDate, payRateFilter]);

  const selectTab = (index: number) => {
    setSelectedTabIndex(index);
    setErrorCode(null);
    setErrorMessage && setErrorMessage("");
    setPayToBillInput("");
    setBillToPayInput("");
    setRateToSimulate("");
    setResponseData(null);

    if (selectedTabIndex === 1) {
      // setPayBillChanged(false);
      // setRateFilter(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (selectedTabIndex === 0) {
      setRateToSimulate(value);

      return;
    }

    setBillToPayInput(value);
  };

  const calculateRates = async (
    ratesForSow: boolean = false,
    isMilestone: boolean = false
  ): Promise<void> => {
    const payload = {
      [(ratesForSow &&
        selectedFilters.deliverableType.name.toLowerCase() ===
          "sow/milestone") ||
      selectedTabIndex === 0
        ? "payRate"
        : "billRate"]:
        ratesForSow &&
        selectedFilters.deliverableType.name.toLowerCase() === "sow/milestone"
          ? "10000"
          : selectedTabIndex === 0
          ? payToBillInput.toString()
          : billToPayInput.toString(),
      isMilestone,
      clientName: selectedClient.label,
      optionalRuleIds:
        updatedOptionalRules && updatedOptionalRules.length > 0
          ? updatedOptionalRules
              .filter((id) => id.checked)
              .map((rule) => Number(rule.id))
          : // ? [6]
            [],
      standardRuleIds: receivedStandardRules
        ? receivedStandardRules.map((rule) => Number(rule.id))
        : //  ? [10]
          [],
      clientId: `${selectedClient.value}`,
    };

    if (selectedTabIndex === 1) {
      payload.engagementType = selectedFilters.engagementType.name;
      payload.mainDivision = selectedFilters.mainDivision.name;
      payload.city = selectedFilters.city.name;
      payload.country = selectedFilters.country.name;
      payload.effectiveDate = selectedDate
        ? `${moment(selectedDate)?.format("YYYY-MM-DD")}`
        : "";
    }

    setLoader(true);

    try {
      const request = PricingCalcApi.quoteRateSimulation(
        payload,
        (ratesForSow &&
          selectedFilters.deliverableType.name.toLowerCase() ===
            "sow/milestone") ||
          selectedTabIndex === 0
          ? "calculatePayToBill"
          : "calculateBillToPay"
      );

      const response = await API.performRequest(request);
      const { data, errors } = response.data;

      setErrorCode(null);
      setErrorMessage && setErrorMessage("");

      if (errors.length > 0) {
        setLoader(false);
        setErrorMessage && setErrorMessage(errors[0].message);
        setErrorCode(errors[0].code);
        setPayRateFilter(null);

        return;
      }

      setLoader(false);

      if (ratesForSow) {
        setSowResponseData(data);
      } else {
        setResponseData(data);
      }

      if (!isMilestone) {
        if (selectedTabIndex === 1) {
          const payRate = data?.payRate?.raw / 100;

          setPayToBillInput(payRate?.toString() || "");
        }
      }
    } catch (error) {
      console.log(error);

      setLoader(false);
    }

    setWarningMsg(false);
  };

  useEffect(() => {
    if (selectedTabIndex === 1) {
      setPayToBillInput(debouncedText);

      setPayRateFilter(null);

      return;
    }

    setPayBillChanged(true);

    if (debouncedText === "") {
      setPayToBillInput("");
      setPayRateFilter(null);

      return;
    }

    setPayToBillInput(debouncedText);

    setPayRateFilter(Number(debouncedText));
  }, [debouncedText]);
  useEffect(() => {
    if (
      ((receivedStandardRules && receivedStandardRules?.length > 0) ||
        (receivedOptionalRules && receivedOptionalRules?.length > 0)) &&
      selectedFilters.deliverableType.name.toLowerCase() === "sow/milestone" &&
      selectedDate
    ) {
      setResponseData(null);
      setSowResponseData(null);

      calculateRates(true, true);
    }
  }, [updatedOptionalRules, buttonClicked, receivedStandardRules]);

  useEffect(() => {
    !simulateDisable &&
      payRateFilter &&
      setPayToBillInput(payRateFilter.toString());
  }, [buttonClicked]);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setRateToSimulate(payToBillInput);
    }

    if (
      !simulateDisable &&
      payRateFilter !== null &&
      Number(payToBillInput) !== payRateFilter &&
      selectedTabIndex === 0 &&
      payToBillInput !== ""
    ) {
      setWarningMsg(true);
    } else {
      setWarningMsg(false);
    }
  }, [payToBillInput]);

  useEffect(() => {
    if (rateFilterChange) {
      return;
    }

    if (payBillChanges && !rateFilterChange) {
      setRateToSimulate(debouncedText);

      setPayBillChanged(false);
      setRateFilterChange(false);
    }
  }, [payBillChanges, debouncedText]);

  useEffect(() => {
    if (payRateFilter || payRateFilter === 0) {
      setPayBillChanged(false);
    }
  }, [payRateFilter]);

  return (
    <div className={classes.container}>
      {selectedFilters.deliverableType.name.toLowerCase() !==
      "sow/milestone" ? (
        <>
          <SimulateHeader
            simulateDisable={simulateDisable}
            selectedTabIndex={selectedTabIndex}
            selectTab={selectTab}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
          />
          <SimulateContent
            input={selectedTabIndex === 0 ? rateToSimulate : billToPayInput}
            simulateDisable={simulateDisable}
            handleInputChange={handleInputChange}
            selectedTabIndex={selectedTabIndex}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
            calculateRates={calculateRates}
            responseData={responseData}
            errorCode={errorCode}
            warningMsg={warningMsg}
            selectedFilters={selectedFilters}
            errorMessage={errorMessage}
          />
        </>
      ) : (
        <>
          {/* milestone component */}
          <SimulateHeader
            simulateDisable={simulateDisable}
            selectedTabIndex={selectedTabIndex}
            selectTab={selectTab}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
            tabIndex={true}
          />
          <SimulateContent
            input={selectedTabIndex === 0 ? rateToSimulate : billToPayInput}
            simulateDisable={simulateDisable}
            handleInputChange={handleInputChange}
            selectedTabIndex={selectedTabIndex}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
            calculateRates={() => calculateRates(true, true)}
            responseData={sowResponseData}
            errorCode={errorCode}
            warningMsg={warningMsg}
            selectedFilters={selectedFilters}
            errorMessage={errorMessage}
          />

          {/* rates component for sow */}
          <SimulateHeader
            simulateDisable={simulateDisable}
            selectedTabIndex={selectedTabIndex}
            selectTab={selectTab}
            sow={true}
            showPayRateBillRate={true}
          />
          <SimulateContent
            input={selectedTabIndex === 0 ? rateToSimulate : billToPayInput}
            simulateDisable={simulateDisable}
            handleInputChange={handleInputChange}
            selectedTabIndex={selectedTabIndex}
            sow={true}
            calculateRates={() => calculateRates(false, true)}
            responseData={responseData}
            errorCode={errorCode}
            warningMsg={warningMsg}
            selectedFilters={selectedFilters}
            showPayRateBillRate={true}
            errorMessage={errorMessage}
          />
        </>
      )}

      <SendJD
        responseData={
          selectedFilters.deliverableType.name.toLowerCase() === "sow/milestone"
            ? sowResponseData
            : responseData
        }
        selectedTabIndex={selectedTabIndex}
        selectedDate={selectedDate}
        selectedFilters={selectedFilters}
        setJobDiva={setJobDiva}
        writeAccess={writeAccess}
      />
    </div>
  );
};

export default SimulateScreen;

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { HiExclamationCircle } from "react-icons/hi";

import {
  stateIprops,
  manageRulesEditIprops,
  FormValidationProps,
  RulesetCreationProps,
} from "../../@types/component";
import API from "../../API/PricingCalcApiPhaseone";
import useQueryData from "../../hooks/useQueryData";
import useStore from "../../store/useStore";
import Button from "../MBOButton/Button";
import MboDate from "../MBOFormDate";
import MBOFormDropdown from "../MBOFormDropdown";
import MBOFormInput from "../MBOFormInput";
import MBOFormRate from "../MBOPriceRangeInput";

import classes from "./index.module.scss";

const EditRules: React.FC<manageRulesEditIprops> = ({
  className,
  dropDownData,
  vendorData,
  getStandardRules,
  LoggedInUser,
  rulesData,
  update,
  selectedClient,
  setForm,
  setIsUpdatedClicked,
  headerText,
  primaryButtonText,
  secondaryButtonText,
  ruleSetCreationid,
  editingQuadRule = false,
  sendDataToAddRulesetParent,
  setDataFlow,
  setRulesData,
  setRulesetCreationState,
  getSubbmittedRulesforRuleset,
  ternaryButton,
  ruleSetInfo,
  disableRulesetField = false,
  setRulesOfRuleset,
}) => {
  const setLoader = useStore((state) => state.setLoader);

  const [isMangeRuleBtnClicked, setManageRuleBtnClick] =
    useState<boolean>(false);

  const { groupData, attributionData, operationData, engagementData } =
    dropDownData;

  const [payloadToSubmit, setPayloadToSubmit] = useState<{}>({});
  const [mappingError, setMappingError] = useState<string>("");

  const updateRules = useQueryData(
    "update-rules",
    {
      ...payloadToSubmit,
    },
    false,
    API.submitBusinessRules
  );

  const amountRef = useRef<HTMLInputElement | null>(null);

  const [updateState, setUpdateState] = useState<stateIprops>({
    name: (rulesData && rulesData.rules?.name) || null,
    description: (rulesData && rulesData.rules?.description) || "",
    effectiveDate:
      (rulesData &&
        rulesData.rules &&
        rulesData.rules.effectiveDate &&
        moment(rulesData.rules.effectiveDate)) ||
      moment("01/01/1900"),
    expireDate:
      (rulesData &&
        rulesData.rules &&
        rulesData.rules.expireDate &&
        moment(rulesData.rules.expireDate)) ||
      null,
    group: {
      label:
        rulesData?.rules?.group?.label ||
        (groupData &&
          groupData.filter((e) => e.name === "Default; Operations from Pay")[0]
            .name) ||
        null,
      value:
        rulesData?.rules?.group?.value ||
        (groupData &&
          groupData.filter((e) => e.name === "Default; Operations from Pay")[0]
            .id) ||
        null,
      isAvailableToMarkdown:
        groupData &&
        groupData.filter((e) => e.name === "Default; Operations from Pay")[0]
          .isAvailableToMarkdown,
    },
    operation: {
      label: rulesData?.rules?.operation?.label || null,
      value: rulesData?.rules?.operation?.value || null,
    },
    amount:
      (rulesData &&
        rulesData.rules &&
        rulesData.rules.amount?.toString().replace("$", "").replace("%", "")) ||
      "",
    attribution: {
      label: rulesData?.rules?.attribution?.label || null,
      value: rulesData?.rules?.attribution?.value || null,
    },
    performedBy: (rulesData && rulesData.rules?.performedBy) || "",
    vendor: {
      label: rulesData?.rules?.vendor?.label || null,
      value: rulesData?.rules?.vendor?.value || null,
    },
    country: (rulesData && rulesData.rules?.country) || "",
    state: (rulesData && rulesData.rules?.state) || "",
    city: (rulesData && rulesData.rules?.city) || "",
    engagementType:
      (rulesData && rulesData.rules && rulesData.rules.engagementType) || "",
    positionLeft: 0,
    positionTop: 0,
    typeOfRule: "",
    createdDate:
      (rulesData &&
        rulesData.rules &&
        moment(rulesData.rules.createdDate).local()) ||
      null,
    updatedDate:
      (rulesData &&
        rulesData.rules &&
        moment(rulesData.rules.updatedDate).local()) ||
      null,
    clientId: (rulesData && rulesData?.rules && rulesData.rules.clientId) || 0,
    id: (rulesData && rulesData?.rules && rulesData.rules.id) || 0,
    rateLow: (rulesData && rulesData.rules && rulesData.rules.rateLow) || "",
    rateHigh: (rulesData && rulesData.rules && rulesData.rules.rateHigh) || "",
    ruleSetInfo: {
      label:
        ruleSetInfo?.filter(
          (e) => Number(e?.value) === Number(ruleSetCreationid)
        )[0]?.label || "",
      value:
        ruleSetInfo?.filter(
          (e) => Number(e?.value) === Number(ruleSetCreationid)
        )[0]?.value || "",
    },
  });

  const [validateForm, setValidateForm] = useState<FormValidationProps>({
    Name: false,
    Group: false,
    Operation: false,
    Amount: false,
    Attribution: false,
    Description: false,
    EffectiveDate: false,
    Ruleset: false,
  });

  const groupHandler =
    groupData &&
    groupData.map((item) => ({
      value: item.id,
      label: item.name,
      isAvailableToMarkdown: item.isAvailableToMarkdown,
    }));

  const vendorhandler =
    vendorData &&
    vendorData.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));

  const operationHandler =
    operationData &&
    operationData.map((item) => ({
      value: (item && item.id) || 0,
      label: (item && item.name) || "",
    }));

  const attributeHandler =
    attributionData &&
    attributionData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const engagementTypeHandler =
    engagementData &&
    engagementData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  useEffect(() => {
    if (amountRef.current) {
      amountRef.current.value =
        (updateState.amount && updateState.amount.toString()) || "";
    }

    setIsUpdatedClicked && setIsUpdatedClicked(false);
  }, []);

  useEffect(() => {
    if (amountRef.current) {
      setUpdateState({ ...updateState, amount: amountRef.current.value });
    }
  }, [amountRef.current]);

  useEffect(() => {
    if (updateState.operation?.label !== "" && amountRef.current) {
      appendSign();
    }
  }, [updateState.operation]);

  const amountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { operation } = updateState;

    if (amountRef.current) {
      setValidateForm({
        ...validateForm,
        Amount: amountRef.current.value === "",
      });

      if (
        (amountRef.current.value.length === 1 &&
          amountRef.current.value === "-") ||
        !Number.isNaN(Math.sign(+amountRef.current.value))
      ) {
        const amountToAdd = e.target.value.trim();

        if (
          (amountRef.current.value.length === 1 &&
            amountRef.current.value === "-") ||
          !Number.isNaN(Math.sign(+amountRef.current.value))
        ) {
          if (
            operation &&
            operation.label !== "flat fee" &&
            amountToAdd.includes(".") &&
            amountToAdd.split(".")[1].length > 5
          ) {
            amountRef.current.value =
              (updateState &&
                updateState.amount &&
                updateState.amount.toString()) ||
              "";

            return;
          }

          if (
            operation &&
            operation.label === "flat fee" &&
            amountToAdd.includes(".") &&
            amountToAdd.split(".")[1].length > 2
          ) {
            amountRef.current.value =
              (updateState &&
                updateState.amount &&
                updateState.amount.toString().split(".")[0] +
                  "." +
                  updateState.amount.toString().split(".")[1].slice(0, 2)) ||
              "";

            return;
          }

          setUpdateState({ ...updateState, amount: amountToAdd });
          amountRef.current.value = amountToAdd.toString() || "";

          return;
        }
      }

      amountRef.current.value = updateState.amount?.toString() || "";
    }
  };

  const appendSign = () => {
    const amount =
      (updateState.amount &&
        updateState.amount?.toString().replace("$", "").replace("%", "")) ||
      "";

    const { operation } = updateState;
    const amountToAdd = amount.trim();

    if (
      operation &&
      operation.label !== "flat fee" &&
      amountToAdd.includes(".") &&
      amountToAdd.split(".")[1].length > 5
    ) {
      return;
    }

    const negativeSign = Math.sign(+amount) === -1 ? "-" : "";
    const rate = Math.abs(+amount);

    if (amountRef.current) {
      if (!rate) {
        amountRef.current.value = "";
        setUpdateState({ ...updateState, amount: "" });

        return;
      }

      if (
        operation &&
        operation.label !== "" &&
        operation.label !== null &&
        amount !== ""
      ) {
        amountRef.current.value =
          operation && operation.label === "flat fee"
            ? `${negativeSign}$${
                Number.isInteger(+rate)
                  ? rate.toFixed(2)
                  : rate?.toString()?.split(".")[0] +
                    "." +
                    rate?.toString()?.split(".")[1]?.slice(0, 2)
              }`
            : `${negativeSign}${rate?.toFixed(5)}%` || rate?.toString();
      } else {
        amountRef.current.value = amount;
      }
    }
  };

  const changeStateValue = (
    type: string,
    value:
      | string
      | number
      | Date
      | { [key: string]: string | number | boolean }
      | null
  ) => {
    setMappingError("");
    setUpdateState({ ...updateState, [type]: value });
  };

  const submitUpdatedRule = () => {
    let ruleId: number | null = null;
    let clientid: string;
    let standardRules: StandardRulePayload | {} = {};

    if (rulesData && !rulesData.rules) {
      ruleId = null;
    } else {
      ruleId = (updateState.id && Number(updateState.id)) || null;
    }

    if (rulesData && !rulesData.rules) {
      clientid = (selectedClient && selectedClient.value.toString()) || "";
    } else {
      clientid = updateState.clientId?.toString() || "";
    }

    const commonRule = {
      ruleSetId: updateState.ruleSetInfo?.value?.toString(),
      id: (ruleId && ruleId) || null,
      clientId: clientid,
      name: updateState.name?.toString() || "",
      description: updateState.description?.toString() || "",
      effectiveDate:
        (updateState.effectiveDate &&
          moment(updateState.effectiveDate).format("YYYY-MM-DD")) ||
        "",
      expireDate:
        (updateState.expireDate &&
          moment(updateState.expireDate).format("YYYY-MM-DD")) ||
        "",
      attributionId:
        (updateState.attribution &&
          updateState.attribution.value &&
          updateState.attribution.value.toString()) ||
        null,
      groupId:
        (updateState.group &&
          updateState.group.value &&
          updateState.group.value.toString()) ||
        null,
      operationId:
        (updateState.operation &&
          updateState.operation.value &&
          updateState.operation.value.toString()) ||
        null,
      amount:
        (updateState.amount &&
          Number(
            updateState.amount.toString().replace("$", "").replace("%", "")
          )) ||
        "",
      currency: "USD",
      s4VendorId:
        (updateState.vendor &&
          updateState.vendor.value &&
          updateState.vendor.value.toString()) ||
        null,
      performedBy: LoggedInUser || "",
      ruleType: rulesData?.type === "standard" ? "STANDARD" : "OPTIONAL",
      engagementType: updateState.engagementType,
    };

    if (rulesData?.type === "standard") {
      standardRules = {
        ...commonRule,
        country: updateState.country?.toString() || "",
        mainDivision: updateState.state?.toString() || "",
        city: updateState.city?.toString() || "",
        rateLow: Number(updateState.rateLow) || null,
        rateHigh: Number(updateState.rateHigh) || null,
      };
    }

    const data =
      rulesData?.type === "standard" ? { ...standardRules } : { ...commonRule };

    setPayloadToSubmit(data);
  };

  useEffect(() => {
    if (Object.keys(payloadToSubmit).length > 0) {
      updateRules.refetch();
    }

    return () => {
      updateRules.remove();
    };
  }, [payloadToSubmit]);

  setLoader(updateRules.isLoading);

  useEffect(() => {
    if (updateRules.error) {
      console.log("");
    }

    if (!updateRules.isLoading && updateRules.data) {
      if (
        "errors" in updateRules.data &&
        updateRules.data.errors.length > 0 &&
        updateRules.data.errors[0].code !== 70100
      ) {
        setMappingError(updateRules.data.errors[0].message);

        return;
      }

      setRulesData && setRulesData(null);
      getSubbmittedRulesforRuleset?.refetch();
      setRulesetCreationState &&
        setRulesetCreationState((prevState: RulesetCreationProps) => {
          return {
            ...prevState,
            screen2: {
              ...prevState.screen2,
              selectedRuleType: "",
            },
          };
        });
      setRulesOfRuleset && setRulesOfRuleset(null);

      setIsUpdatedClicked && setIsUpdatedClicked(true);
      setForm(false);
    }
  }, [updateRules.data, updateRules.error, updateRules.isLoading]);

  useEffect(() => {
    if (isMangeRuleBtnClicked) {
      setManageRuleBtnClick(false);

      if (validateForm && !Object.values(validateForm).includes(true)) {
        if (sendDataToAddRulesetParent && editingQuadRule) {
          sendDataToAddRulesetParent(rulesData?.type || "", updateState);

          return;
        }

        submitUpdatedRule();
      }
    }
  }, [validateForm, isMangeRuleBtnClicked]);

  const validateFormToSubmit = () => {
    setValidateForm({
      ...validateForm,
      Name: Boolean(updateState.name === "" || updateState.name === null),
      Description: Boolean(
        updateState.description === "" || updateState.description === null
      ),
      Operation:
        Boolean(
          updateState?.operation?.label === "" ||
            updateState?.operation?.label === null
        ) ||
        Boolean(
          operationHandler &&
            operationHandler.findIndex((e) =>
              e.label.includes(
                (updateState && updateState?.operation?.label?.toString()) || ""
              )
            ) < 0
        ),
      Attribution:
        Boolean(
          updateState?.attribution?.label === "" ||
            updateState?.attribution?.label === null
        ) ||
        Boolean(
          attributeHandler &&
            attributeHandler.findIndex((e) =>
              e.label.includes(
                (updateState && updateState?.attribution?.label?.toString()) ||
                  ""
              )
            ) < 0
        ),
      Group:
        Boolean(
          updateState?.group?.label === "" || updateState?.group?.label === null
        ) ||
        Boolean(
          groupHandler &&
            groupHandler.findIndex((e) =>
              e.label.includes(
                (updateState && updateState?.group?.label?.toString()) || ""
              )
            ) < 0
        ),
      EffectiveDate: Boolean(
        updateState.effectiveDate?.toString() === "" ||
          updateState.effectiveDate === null
      ),
      Amount: !(updateState && updateState.amount && updateState.amount !== ""),
      Ruleset:
        Boolean(
          updateState?.ruleSetInfo?.label === "" ||
            updateState?.ruleSetInfo?.label === null
        ) ||
        Boolean(
          ruleSetInfo &&
            ruleSetInfo.findIndex((e) =>
              e.label.includes(
                (updateState && updateState?.ruleSetInfo?.label?.toString()) ||
                  ""
              )
            ) < 0
        ),
    });
  };

  return (
    <>
      <div className={classes.blocker}></div>
      <div className={classes.overlay}></div>
      <div className={classes.popup_blur}></div>
      <div className={classes.rules_edit_popup} data-testid="editrules">
        <div className={classes.aligning_container}>
          <h1 className={classes.header}>
            {headerText && headerText !== ""
              ? headerText
              : rulesData && rulesData.rules
              ? "Update a rule"
              : "Create a rule"}
          </h1>
          <p className={classes.sub_header}>
            All fields are mandatory, unless marked as optional
          </p>
          <div className={classes.form_container}>
            <div className={classes.elements_position}>
              <MBOFormDropdown
                placeholder="Ruleset"
                onSelect={(item: unknown) => {
                  const data = item as { value: number; label: string };

                  setValidateForm({
                    ...validateForm,
                    Ruleset: !data.label || data.label === "",
                  });

                  changeStateValue("ruleSetInfo", {
                    label: data.label,
                    value: data.value,
                  });
                }}
                disable={disableRulesetField}
                options={
                  ruleSetInfo?.map((e) => ({ ...e, value: Number(e.value) })) ||
                  []
                }
                value={[
                  {
                    label: updateState?.ruleSetInfo?.label || "",
                    value: Number(updateState?.ruleSetInfo?.value),
                  },
                ]}
                alterStyle={
                  validateForm.Ruleset ? classes.form_error_style : ""
                }
                showRequiredFieldMessage={validateForm.Ruleset}
              />
              <MBOFormInput
                placeholder="Name"
                value={updateState.name?.toString()}
                changeHandler={(item: string | unknown) => {
                  if (item) {
                    const data = item as React.ChangeEvent<HTMLInputElement>;

                    setValidateForm({
                      ...validateForm,
                      Name: !data.target.value || data.target.value === "",
                    });
                    changeStateValue("name", data.target.value);
                  }
                }}
                className={validateForm.Name ? classes.form_error_style : ""}
                showRequiredFieldMessage={validateForm.Name}
              />
            </div>
            <div className={classes.elements_position}>
              <MBOFormInput
                placeholder="Description"
                value={updateState.description?.toString()}
                changeHandler={(item: string | unknown) => {
                  if (item) {
                    const data = item as React.ChangeEvent<HTMLInputElement>;

                    setValidateForm({
                      ...validateForm,
                      Description:
                        !data.target.value || data.target.value === "",
                    });

                    changeStateValue("description", data.target.value);
                  }
                }}
                className={
                  validateForm.Description ? classes.form_error_style : ""
                }
                showRequiredFieldMessage={validateForm.Description}
              />
              <MboDate
                value={
                  updateState.effectiveDate
                    ? moment(updateState.effectiveDate).toDate()
                    : null
                }
                placeholderText={"Effective Date"}
                className={classes.alter_date_styles}
                onChange={(item: unknown) => {
                  let time = item as Date | number;

                  time = new Date(time).getTime();

                  setValidateForm({
                    ...validateForm,
                    EffectiveDate: !time || time.toString() === "",
                  });

                  changeStateValue("effectiveDate", time === 0 ? null : time);
                }}
                errorStyle={
                  validateForm.EffectiveDate ? classes.data_mandatory : ""
                }
                errorText={validateForm.EffectiveDate ? "! Required" : ""}
              />
            </div>
            <div className={classes.elements_position}>
              <MboDate
                value={
                  updateState.expireDate
                    ? moment(updateState.expireDate).toDate()
                    : null
                }
                onChange={(item: unknown) => {
                  let time = item as Date | number;

                  time = new Date(time).getTime();

                  changeStateValue("expireDate", time);
                }}
                placeholderText={"Expire Date (Optional)"}
                className={classes.alter_date_styles}
              />
              <MBOFormDropdown
                placeholder="Group"
                onSelect={(item: unknown) => {
                  const data = item as {
                    value: number;
                    label: string;
                    isAvailableToMarkdown: boolean;
                  };

                  setValidateForm({
                    ...validateForm,
                    Group: !data.label || data.label === "",
                  });

                  changeStateValue("group", {
                    label: data.label,
                    value: data.value,
                    isAvailableToMarkdown: data.isAvailableToMarkdown,
                  });
                }}
                options={groupHandler || []}
                value={[
                  groupHandler?.find(
                    (e) => Number(e.value) === Number(updateState?.group?.value)
                  ) || {
                    label: "",
                    value: 0,
                  },
                ]}
                alterStyle={validateForm.Group ? classes.form_error_style : ""}
                showRequiredFieldMessage={validateForm.Group}
              />
            </div>
            <div className={classes.elements_position}>
              <MBOFormDropdown
                placeholder="Operation"
                onSelect={(item: unknown) => {
                  const data = item as {
                    value: number;
                    label: string;
                    isAvailableToMarkdown: boolean;
                  };

                  setValidateForm({
                    ...validateForm,
                    Operation: !data.label || data.label === "",
                  });

                  changeStateValue("operation", {
                    label: data.label,
                    value: data.value,
                  });
                }}
                options={operationHandler || []}
                value={[
                  operationHandler?.find(
                    (e) =>
                      Number(e.value) === Number(updateState?.operation?.value)
                  ) || {
                    label: "",
                    value: 0,
                  },
                ]}
                alterStyle={
                  validateForm.Operation ? classes.form_error_style : ""
                }
                showRequiredFieldMessage={
                  validateForm.Operation ||
                  (!updateState?.group?.isAvailableToMarkdown &&
                    !validateForm.Operation &&
                    updateState.operation?.label === "markdown") ||
                  false
                }
                errorMessage={
                  !updateState?.group?.isAvailableToMarkdown &&
                  !validateForm.Operation &&
                  updateState.operation?.label === "markdown"
                    ? "Markdowns will always be applied to the Net Bill Rate regardless of Group selection. Review your selections to ensure accuracy."
                    : ""
                }
              />

              <MBOFormInput
                innerRef={amountRef}
                value={
                  (amountRef && amountRef.current && amountRef.current.value) ||
                  ""
                }
                placeholder="Amount"
                onBlur={appendSign}
                onFocus={() => {
                  if (amountRef.current) {
                    const { operation } = updateState;

                    const amount =
                      updateState.amount === "" || !updateState.amount
                        ? ""
                        : Number.isInteger(+updateState.amount)
                        ? updateState.amount.toString()
                        : operation && operation.label !== "flat fee"
                        ? Number(
                            updateState.amount
                              ?.toString()
                              .replace("$", "")
                              .replace("%", "")
                          ).toFixed(5)
                        : updateState &&
                          updateState.amount &&
                          Number(
                            `${
                              updateState.amount
                                ?.toString()
                                .replace("$", "")
                                .replace("%", "")
                                .split(".")[0]
                            }.${updateState.amount
                              ?.toString()
                              .replace("$", "")
                              .replace("%", "")
                              .split(".")[1]
                              .slice(0, 2)}`
                          ).toFixed(2);

                    amountRef.current.value = amount || "";
                  }
                }}
                type={"number"}
                changeHandler={amountHandler}
                className={validateForm.Amount ? classes.form_error_style : ""}
                showRequiredFieldMessage={validateForm.Amount}
              />
            </div>
            <div className={classes.elements_position}>
              <MBOFormInput
                placeholder="Performed by"
                value={updateState.performedBy?.toString() || LoggedInUser}
                disabled={true}
              />
              <MBOFormDropdown
                placeholder="Attribution"
                onSelect={(item: unknown) => {
                  const data = item as { value: number; label: string };

                  setValidateForm({
                    ...validateForm,
                    Attribution: !data.label || data.label === "",
                  });

                  changeStateValue("attribution", {
                    label: data.label,
                    value: data.value,
                  });
                }}
                options={attributeHandler || []}
                value={[
                  attributeHandler?.find(
                    (e) =>
                      Number(e.value) ===
                      Number(updateState?.attribution?.value)
                  ) || { label: "", value: 0 },
                ]}
                alterStyle={
                  validateForm.Attribution ? classes.form_error_style : ""
                }
                showRequiredFieldMessage={validateForm.Attribution}
              />
            </div>
            {rulesData && rulesData.type && rulesData.type === "standard" ? (
              <div className={classes.elements_position}>
                <div className={classes.location_styles}>
                  <MBOFormDropdown
                    placeholder="Country (Optional)"
                    value={[
                      {
                        label: updateState.country?.toString() || "",
                        value: 0,
                      },
                    ]}
                    options={[{ label: "USA", value: 0 }]}
                    onSelect={(item: string | unknown) => {
                      if (item) {
                        const data = item as { value: number; label: string };

                        changeStateValue("country", data.label);
                      }
                    }}
                    alterStyle={classes.alter_location_width}
                  />
                  <MBOFormDropdown
                    placeholder="State (Optional)"
                    options={[]}
                    value={[
                      {
                        label: updateState.state?.toString() || "",
                        value: 0,
                      },
                    ]}
                    onSelect={(item: string | unknown) => {
                      if (item) {
                        const data = item as { value: number; label: string };

                        changeStateValue("state", data.label.trim());
                      }
                    }}
                    alterStyle={classes.alter_location_width}
                  />
                </div>
                <MBOFormDropdown
                  placeholder="City (Optional)"
                  options={[]}
                  value={[
                    {
                      label: updateState.city?.toString() || "",
                      value: 0,
                    },
                  ]}
                  onSelect={(item: string | unknown) => {
                    if (item) {
                      const data = item as { value: number; label: string };

                      changeStateValue("city", data.label.trim());
                    }
                  }}
                />
              </div>
            ) : null}
            <div className={classes.elements_position}>
              <MBOFormDropdown
                placeholder="Vendor (Optional)"
                onSelect={(item: unknown) => {
                  const data = item as { value: number; label: string };

                  changeStateValue("vendor", {
                    label: data.label,
                    value: data.value,
                  });
                }}
                options={vendorhandler || []}
                value={[
                  vendorhandler?.find(
                    (e) =>
                      Number(e.value) === Number(updateState?.vendor?.value)
                  ) || { label: "", value: 0 },
                ]}
              />
              <MBOFormDropdown
                placeholder="Engagement Type"
                onSelect={(item: unknown) => {
                  const data = item as { value: number; label: string };

                  changeStateValue("engagementType", data.label);
                }}
                options={engagementTypeHandler || []}
                value={[
                  engagementTypeHandler?.find(
                    (e) => e.label === updateState.engagementType
                  ) || { label: "", value: 0 },
                ]}
              />
            </div>
            <div className={classes.elements_position}>
              {rulesData && rulesData.type && rulesData.type === "standard" ? (
                <MBOFormRate
                  rateLow={updateState.rateLow || ""}
                  rateHigh={updateState.rateHigh || ""}
                  changeHandler={(type: string, value: string) => {
                    setValidateForm({
                      ...validateForm,
                      [type]: value,
                    });

                    changeStateValue(type, value);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className={classes.btn_position}>
          {mappingError && mappingError !== "" && (
            <p className={classes.mapping_error}>
              <HiExclamationCircle fill={"red"} fontSize={20} />
              {mappingError}
            </p>
          )}

          <Button
            name={"Cancel"}
            className={classes.btn_calcel_styling}
            onClick={() => {
              setMappingError("");

              setForm(false);
              getStandardRules && getStandardRules(null, "");
            }}
          />

          <Button
            name={rulesData && rulesData.rules ? "Update" : "Create"}
            className={classes.btn_update_styling}
            onClick={() => {
              setManageRuleBtnClick(true);
              setMappingError("");
              validateFormToSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditRules;

import React from "react";
import { BsArrowRepeat } from "react-icons/bs";

import {
  engagementTypeListProps,
  getLocationProps,
  optionsProp,
  selectedItemsProps,
} from "../../../../../@types/component";
import { CaretRight } from "../../../../../componentsPhase1/Icon/Icons/CaretRight";
import MBOButton from "../../../../../componentsPhase1/MBOButton";
import MBODropdown from "../../../../../componentsPhase1/MBODropdown";
import MBOInput from "../../../../../componentsPhase1/MBOInput";

import classes from "./filterbar.module.scss";

interface FilterSectionProps {
  selectedClient: optionsProp;
  engagementTypeList: engagementTypeListProps[];
  clearEngagementType: (filterType: string) => void;
  handleFilterChange: (
    filterType: string,
    filterValue: engagementTypeListProps
  ) => void;
  selectedFilters: selectedItemsProps;
  handleReset: () => void;
  selectedDate: Date | null;
  handleDateChange: (val: Date | null) => void;
  handleDateClear: () => void;
  handleFetchRules: () => void;
  locationData: getLocationProps | null;
  handleRateFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  payRateFilter: number | null;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  selectedClient,
  engagementTypeList,
  clearEngagementType,
  selectedFilters,
  handleFilterChange,
  handleReset,
  selectedDate,
  handleDateChange,
  handleDateClear,
  handleFetchRules,
  locationData,
  handleRateFilterChange,
  payRateFilter,
}) => {
  const requiredCountry =
    locationData &&
    locationData.clients &&
    locationData.clients[0].engagementTypes.find(
      (item) => item.name === selectedFilters.engagementType.name
    )?.countries;

  const requiredMainDivision = requiredCountry?.find(
    (item) => item.name === selectedFilters.country.name
  )?.states;

  const requiredCity = requiredMainDivision?.find(
    (item) => item.name === selectedFilters.mainDivision.name
  )?.cities;

  const rateFilterMandatory =
    locationData && locationData.clients
      ? locationData.clients[0].engagementTypes.find(
          (item) => item.name === selectedFilters.engagementType.name
        )?.isRatePresent
      : false;

  return (
    <div className={classes.filterSection} data-testid="filter_bar">
      <div className={classes.filters}>
        <MBOInput
          type="text"
          disabled={true}
          placeholder=""
          name="clientName"
          inputValue={selectedClient.label}
          isClearIconRequired={false}
          appendStyles={classes.client_input}
        />
        <MBODropdown
          disabled={selectedClient.label === ""}
          name="Engagement Type"
          options={engagementTypeList}
          clearHandler={clearEngagementType}
          selectedValue={selectedFilters.engagementType}
          handleValueChange={handleFilterChange}
          propertyName="engagementType"
        />
        <MBODropdown
          disabled={selectedFilters.engagementType.name === ""}
          name="Country"
          options={requiredCountry?.map((item, index) => {
            return { name: item.name, id: index };
          })}
          clearHandler={clearEngagementType}
          selectedValue={selectedFilters.country}
          handleValueChange={handleFilterChange}
          propertyName="country"
        />
        <MBODropdown
          disabled={selectedFilters.country.name === ""}
          name="State"
          options={requiredMainDivision
            ?.map((item, index) => {
              return { name: item.name, id: index };
            })
            .sort((a, b) => a.name.localeCompare(b.name))}
          clearHandler={clearEngagementType}
          selectedValue={selectedFilters.mainDivision}
          handleValueChange={handleFilterChange}
          propertyName="mainDivision"
        />
        <MBODropdown
          disabled={selectedFilters.mainDivision.name === ""}
          name="City"
          options={requiredCity
            ?.map((item, index) => {
              return { name: item.name, id: index };
            })
            .sort((a, b) => a.name.localeCompare(b.name))}
          clearHandler={clearEngagementType}
          selectedValue={selectedFilters.city}
          handleValueChange={handleFilterChange}
          propertyName="city"
        />
        <MBODropdown
          name="Effective Date"
          clearHandler={clearEngagementType}
          selectedValue={selectedFilters.engagementType}
          handleValueChange={handleFilterChange}
          handleDateChange={handleDateChange}
          propertyName="effectiveDate"
          dateDropdown={true}
          options={[]}
          selectedDate={selectedDate}
          handleDateClear={handleDateClear}
        />
        <div className={classes.input_section}>
          <MBOInput
            type="number"
            placeholder="Pay Rate"
            name="Simulate Value"
            isClearIconRequired={false}
            inputValue={payRateFilter}
            appendStyles={classes.input}
            changeHandler={handleRateFilterChange}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className={classes.manageSection}>
        <MBOButton
          name="Reset"
          onClick={handleReset}
          symbol={<BsArrowRepeat />}
          appendStyle={classes.resetButton}
        />
        <MBOButton
          name="Apply"
          onClick={handleFetchRules}
          symbol={<CaretRight fill="#fff" />}
          disabled={
            selectedFilters.engagementType.name === "" ||
            selectedDate === null ||
            (rateFilterMandatory &&
              (payRateFilter === null || payRateFilter === 0))
          }
        />
      </div>
    </div>
  );
};

export default FilterSection;

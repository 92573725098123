import React from "react";
import { HiExclamationCircle } from "react-icons/hi";

import { selectedItemsProps } from "../../../../../@types/component";
import { CaretRight } from "../../../../../componentsPhase1/Icon/Icons/CaretRight";
import MBOButton from "../../../../../componentsPhase1/MBOButton";
import MBOInput from "../../../../../componentsPhase1/MBOInput";

import CalculationsTable from "./CalculationTable";
// import RatesTable from "./RatesTable";

import classes from "./content.module.scss";

interface SimulateContentProps {
  input: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedTabIndex: number;
  sow: boolean;
  calculateRates: () => Promise<void>;
  responseData: CalcAtributes["data"] | null;
  simulateDisable: boolean;
  errorCode: number | null;
  warningMsg: boolean;
  selectedFilters: selectedItemsProps;
  showPayRateBillRate?: boolean;
  errorMessage?: string;
}

const SimulateContent: React.FC<SimulateContentProps> = ({
  input,
  handleInputChange,
  selectedTabIndex,
  sow = false,
  calculateRates,
  responseData,
  simulateDisable,
  errorCode,
  warningMsg,
  selectedFilters,
  showPayRateBillRate = false,
  errorMessage = "",
}) => {
  return (
    <>
      {(!sow || showPayRateBillRate) && (
        <>
          <div
            className={[
              classes.input_section,
              simulateDisable && classes.simulate_disable,
            ].join(" ")}
          >
            <MBOInput
              type="number"
              placeholder="Hourly Rate"
              name="Simulate Value"
              isClearIconRequired={false}
              inputValue={simulateDisable ? "" : input}
              appendStyles={classes.input}
              changeHandler={handleInputChange}
              disabled={simulateDisable}
            />
            <MBOButton
              name="Calculate"
              onClick={() => {
                calculateRates();
              }}
              symbol={<CaretRight fill="white" />}
              appendStyle={[
                classes.calculate_button,
                input.length === 0 ? classes.disabled_button : "",
              ].join(" ")}
              disabled={input.length === 0 || simulateDisable}
            />
          </div>
        </>
      )}
      {!showPayRateBillRate && sow && (
        <div
          className={[
            classes.warning_message,
            simulateDisable && classes.simulate_disable,
          ].join(" ")}
        >
          <HiExclamationCircle
            fill={
              simulateDisable
                ? "#B8B3BC"
                : errorCode &&
                  (errorCode === 70013 ||
                    errorCode === 70046 ||
                    errorCode === 70048 ||
                    errorCode === 70047)
                ? "red"
                : "#007F9C"
            }
            fontSize={16}
          />
          {errorCode &&
          (errorCode === 70048 ||
            errorCode === 70046 ||
            errorCode === 70047) ? (
            <span className={classes.error_message}>{errorMessage}</span>
          ) : errorCode && errorCode === 70013 ? (
            <span className={classes.error_message}>
              No rules are applied for the simulation
            </span>
          ) : (
            <span>
              The rules in the left are used for this simulation. <br />
              Filtering the rules will automatically update the simulation.
            </span>
          )}
        </div>
      )}
      {((showPayRateBillRate && sow) || (!showPayRateBillRate && !sow)) && (
        <div
          className={[
            classes.warning_message,
            simulateDisable && classes.simulate_disable,
          ].join(" ")}
        >
          <HiExclamationCircle
            fill={
              simulateDisable
                ? "#B8B3BC"
                : errorCode &&
                  (errorCode === 70013 ||
                    errorCode === 70046 ||
                    errorCode === 70048 ||
                    errorCode === 70047)
                ? "red"
                : "#007F9C"
            }
            fontSize={16}
            className={errorCode ? classes.error_icon : ""}
          />
          {errorCode &&
          (errorCode === 70048 ||
            errorCode === 70046 ||
            errorCode === 70047) ? (
            <span className={classes.error_message}>{errorMessage}</span>
          ) : errorCode && errorCode === 70013 ? (
            <span className={classes.error_message}>
              No rules are applied for the simulation
            </span>
          ) : (
            <span>
              The rules in the left are used for this simulation. <br />
              Filtering the rules will automatically update the simulation.
            </span>
          )}
        </div>
      )}
      {warningMsg &&
        ((showPayRateBillRate && sow) || (!showPayRateBillRate && !sow)) && (
          <div
            className={[
              classes.warning_message,
              simulateDisable && classes.simulate_disable,
            ].join(" ")}
          >
            <HiExclamationCircle
              fill={simulateDisable ? "#B8B3BC" : "orange"}
              fontSize={18}
            />
            <span>
              Warning: Proposed Pay rate is different from Rate values given in
              the rate filter
            </span>
          </div>
        )}
      {((responseData && !errorCode) || (responseData && sow)) && (
        <>
          <CalculationsTable
            input={input}
            selectedTabIndex={selectedTabIndex}
            sow={sow}
            responseData={responseData}
            selectedFilters={selectedFilters}
            showPayRateBillRate={showPayRateBillRate}
          />
          {/* {!sow && <RatesTable input={input} responseData={responseData} />} */}
        </>
      )}
    </>
  );
};

export default SimulateContent;

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import moment from "moment";
import { RefObject, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { AiOutlineMinus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FcDeleteDatabase } from "react-icons/fc";
import {
  RiArrowRightSLine,
  RiArrowLeftSLine,
  RiArrowUpSLine,
  RiArrowDownSLine,
} from "react-icons/ri";
import { delegate } from "tippy.js";
import "tippy.js/animations/shift-toward-subtle.css";
import "tippy.js/dist/tippy.css";

import { stateIprops } from "../../@types/component";
import ErrorBoundary from "../../HOC/ErrorBoundary/errorBoundary.component";
import MBOButton from "../../componentsPhase1/MBOButton/Button";
import useHorizontalScroll from "../../hooks/useHorizontalScroll";
import MBOToggle from "../MBOToggle";

import classes from "./index.module.scss";

interface TableDataIProps {
  rule: stateIprops[];
  showAddNewButton: boolean;
  settingsStyle?: string;
  headerName: string;
  headerClassName?: string;
  typeOfRule?: string;
  isEditRequired: boolean;
  isToggleRequired: boolean;
  appendStyle?: string;
  numberOfColumnsToShow?: number;
  getCLickPosition?: (rule: stateIprops | null, type: string) => void;
  setForm?: (e: boolean) => void;
  setDeleteForm?: (e: boolean) => void;
  getDeletedId?: (
    ruleId: number | null,
    ruleSetId: number | null,
    type: string
  ) => void;
  getOptionalRules?: (
    updatedOR: {
      id: number;
      checked: boolean;
    }[]
  ) => void;
  clearPopOver?: (typeOfRule: string) => void;
  isEstimatePayment?: boolean;
  isAdvancedViewRequired?: boolean;
  editToggleSwitchHandler?: (e: {
    settingsId: string;
    isActive: boolean;
    updatedBy: string;
  }) => void;
  getEstimatePaymentRule?: (e: stateIprops) => void;
  writeAccess?: boolean;
  PrimaryButtonName?: string;
  selectedRulesId?: number;
  popoverRef?: RefObject<HTMLDivElement | null>;
  children?: React.ReactNode;
}

const MBOTable: React.FC<TableDataIProps> = ({
  rule,
  showAddNewButton,
  headerName,
  headerClassName,
  typeOfRule,
  isEditRequired,
  isToggleRequired,
  appendStyle,
  numberOfColumnsToShow,
  getCLickPosition,
  setForm,
  getDeletedId,
  getOptionalRules,
  clearPopOver,
  isEstimatePayment = false,
  settingsStyle = "",
  isAdvancedViewRequired = true,
  editToggleSwitchHandler,
  getEstimatePaymentRule,
  writeAccess = true,
  PrimaryButtonName = "Add New",
  selectedRulesId,
  popoverRef,
  children,
}) => {
  delegate("body", {
    interactive: true,
    allowHTML: true,
    animation: "shift-toward-subtle",
    target: "[data-tippy-content]",
    appendTo: document.body,
    theme: "light",
  });

  const { showBoundary } = useErrorBoundary();
  const [updateState, setUpdateState] = useState<stateIprops | null>(null);
  const { scrollRef, scrollToStart, scrollToEnd } = useHorizontalScroll();
  const [simpleView, setToSimpleView] = useState(true);
  const [collapseTable, setTableCollapse] = useState(false);
  const [showAlldata, setShowAlldata] = useState(false);
  const { authState } = useOktaAuth();
  const [tableData, setTableData] = useState<stateIprops[] | null>(null);

  const [showEditPopUp, editPopUp] = useState<
    { id: number; checked: boolean }[]
  >(
    _.map(
      tableData,

      (val, key) => {
        return { id: (val.id && Number(val.id)) || 0, checked: false };
      }
    )
  );

  const [toggleRow, setToggle] = useState<{ id: number; checked: boolean }[]>(
    []
  );

  useEffect(() => {
    setTableData(rule);

    return () => {
      setTableData(null);
    };
  }, [rule]);

  useEffect(() => {
    try {
      if (
        (tableData &&
          tableData.length > 0 &&
          toggleRow.length !== tableData.length) ||
        (tableData && toggleRow.length !== tableData.length) ||
        (isEstimatePayment &&
          tableData &&
          tableData.length > 0 &&
          tableData.map((e) => e?.isActive).join(",") !==
            toggleRow.map((e) => e.checked).join(","))
      ) {
        const dataToUseForChecking: { id: number; checked: boolean }[] = _.map(
          tableData,

          (val, key) => {
            return {
              id: (val.id && Number(val.id)) || 0,
              checked: isEstimatePayment && val.isActive ? val.isActive : false,
            };
          }
        );

        setToggle(dataToUseForChecking);
      }
    } catch (e) {
      showBoundary("");
    }
  }, [tableData, toggleRow, showEditPopUp]);

  const fnChangeToSimpleView = (type: boolean) => {
    setToSimpleView(type);
  };

  useEffect(() => {
    if (updateState) {
      getCLickPosition && getCLickPosition(updateState, typeOfRule || "");
    }
  }, [updateState]);

  useEffect(() => {
    toggleRow && getOptionalRules && getOptionalRules(toggleRow);
  }, [toggleRow]);

  return (
    <>
      <div
        className={[classes.table_accordion, appendStyle].join(" ")}
        data-testid="element-with-onMouseDown"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clearPopOver && clearPopOver(typeOfRule || "");
        }}
      >
        <div
          className={[
            classes.table_header,
            collapseTable ? classes.table_header_border : "",
            headerClassName,
          ]
            .filter(Boolean)
            .join(" ")}
        >
          <div className={classes.header_first_section}>
            <span>{headerName}</span>
            {isAdvancedViewRequired && tableData && tableData.length > 0 && (
              <span>
                {!simpleView ? (
                  <>
                    <RiArrowRightSLine /> <RiArrowLeftSLine />{" "}
                    <p onClick={() => fnChangeToSimpleView(true)}>
                      Simplified View
                    </p>
                  </>
                ) : (
                  <>
                    <RiArrowLeftSLine />
                    <RiArrowRightSLine />
                    <p onClick={() => fnChangeToSimpleView(false)}>
                      Advanced View
                    </p>
                  </>
                )}
              </span>
            )}
          </div>
          <AiOutlineMinus
            onClick={() => {
              setTableCollapse(!collapseTable);
            }}
          />
        </div>
        {!collapseTable && !simpleView && (
          <>
            <div
              className={[
                classes.scroll_left,
                showAlldata
                  ? (tableData &&
                      tableData.length > 5 &&
                      classes.scroll_top_position_max_width) ||
                    ""
                  : classes.scroll_top_position_min_width,
              ].join(" ")}
            >
              <RiArrowLeftSLine onClick={scrollToStart} />
            </div>
            <div
              className={[
                classes.scroll_right,
                showAlldata
                  ? (tableData &&
                      tableData.length > 5 &&
                      classes.scroll_top_position_max_width) ||
                    ""
                  : classes.scroll_top_position_min_width,
              ].join(" ")}
            >
              <RiArrowRightSLine onClick={scrollToEnd} />
            </div>
          </>
        )}
        <div
          className={[
            classes.group,

            classes.group2,

            collapseTable
              ? classes.table_accordion_collapse
              : showAlldata
              ? classes.show_full_data
              : "",
          ].join(" ")}
          ref={scrollRef}
        >
          <ul
            className={[classes.ul, classes.two_ul_width_for_min_data].join(
              " "
            )}
          >
            {_.map(tableData, (value, parentKey) => {
              let i = 1;

              return (
                <li
                  className={[
                    classes.row_bgcolor,
                    classes.group2_li_min_width_display,
                  ].join(" ")}
                  key={`${value.id || ""}${parentKey}`}
                >
                  {_.map(value, (val, key) => {
                    if (simpleView) {
                      const valueToCompare =
                        Number(numberOfColumnsToShow) + 3 || 10;

                      if (i <= valueToCompare) {
                        i++;
                      } else {
                        return null;
                      }
                    }

                    if (
                      key === "id" ||
                      key === "engagementTypeId" ||
                      key === "engagementTypeName" ||
                      key === "clientId" ||
                      key === "isActive" ||
                      key === "ruleSetId" ||
                      key === "uniqueid"
                    ) {
                      return null;
                    }

                    let valueToAppend: string = "";

                    const isValueADate = moment.isMoment(val);

                    if (isValueADate) {
                      const DateValue = val;

                      valueToAppend =
                        (DateValue && DateValue.format("MMM | DD | YYYY")) ||
                        "";
                    } else if (typeof val === "object") {
                      const newVal = val as { value: number; label: string };

                      valueToAppend = (newVal && newVal.label) || "";
                    } else {
                      valueToAppend = (val && val.toString()) || "";
                    }

                    return (
                      <div
                        className={[
                          classes.data,
                          !simpleView
                            ? classes.group_tow_p_wdith_for_min_data
                            : "",
                        ].join(" ")}
                        key={`${parentKey}${key}`}
                      >
                        <div>
                          <span>
                            {key
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, (str) => str.toUpperCase())}
                          </span>

                          {key === "comment" &&
                          headerName === "Settings" &&
                          valueToAppend !== "" &&
                          valueToAppend.length > 10 ? (
                            <span
                              className={
                                key === "comment"
                                  ? classes.comment_cellwidth
                                  : ""
                              }
                              data-tippy-content={valueToAppend}
                              data-tippy-theme="custom"
                            >
                              {" "}
                              {valueToAppend || <br />}
                            </span>
                          ) : (
                            <span
                              className={
                                key === "comment"
                                  ? classes.comment_cellwidth
                                  : key === "description" &&
                                    typeOfRule === "ruleset"
                                  ? classes.comment_cellwidth_for_ruleset_desc
                                  : ""
                              }
                            >
                              {valueToAppend || <br />}
                            </span>
                          )}
                        </div>
                        <hr className={classes.cell_divider} />
                      </div>
                    );
                  })}
                  {(isToggleRequired || isEditRequired) && (
                    <div
                      className={[classes.edit_row_cell, settingsStyle].join(
                        " "
                      )}
                    >
                      <>
                        {isEditRequired && !isToggleRequired && (
                          <div>
                            {writeAccess ? (
                              <Popover
                                placement="left-start"
                                showArrow={true}
                                backdrop="blur"
                                ref={popoverRef}
                                id={`${typeOfRule || ""}${parentKey}popover`}
                                className={classes.alter_popover_style}
                              >
                                <PopoverTrigger>
                                  <Button
                                    className={
                                      classes.alter_popover_button_style
                                    }
                                    id={`${typeOfRule || ""}${parentKey}button`}
                                    onClick={(e) => {
                                      getDeletedId &&
                                        getDeletedId(
                                          (value && Number(value.id)) || null,
                                          (value && Number(value.ruleSetId)) ||
                                            null,
                                          typeOfRule || ""
                                        );

                                      setUpdateState({
                                        name: value.name,
                                        id: value.id,
                                        clientId: value.clientId,
                                        description: value.description,
                                        effectiveDate:
                                          (value.effectiveDate &&
                                            moment(value.effectiveDate)) ||
                                          null,
                                        expireDate:
                                          (value.expireDate &&
                                            moment(value.expireDate)) ||
                                          null,
                                        group: {
                                          label: value?.group?.label || "",
                                          value: value?.group?.value || 0,
                                          isAvailableToMarkdown:
                                            value.group
                                              ?.isAvailableToMarkdown || null,
                                        },
                                        operation: {
                                          label: value?.operation?.label || "",
                                          value: value?.operation?.value || 0,
                                        },
                                        amount: Number(
                                          value.amount
                                            ?.toString()
                                            .replace("$", "")
                                            .replace("%", "")
                                        ),
                                        attribution: {
                                          label:
                                            value?.attribution?.label || "",
                                          value: value?.attribution?.value || 0,
                                        },
                                        performedBy: value.performedBy,
                                        vendor: {
                                          label:
                                            (value.vendor &&
                                              value.vendor.label) ||
                                            "",
                                          value:
                                            (value.vendor &&
                                              value.vendor.value) ||
                                            0,
                                        },
                                        country: value.country,
                                        state: value.state,
                                        city: value.city,
                                        engagementType: value.engagementType,
                                        typeOfRule: typeOfRule || "",
                                        createdDate: value.createdDate,
                                        updatedDate: value.updatedDate,
                                        rateLow: value.rateLow,
                                        rateHigh: value.rateHigh,
                                        ruleSetId: `${value.ruleSetId || ""}`,
                                        uniqueid: `${
                                          typeOfRule || ""
                                        }${parentKey}`,
                                      });

                                      editPopUp(
                                        showEditPopUp.map((e) => {
                                          if (value.id) {
                                            if (e.id === value.id) {
                                              return {
                                                id: value.id,
                                                checked: !e.checked,
                                              };
                                            }
                                          }

                                          return { id: e.id, checked: false };
                                        })
                                      );
                                    }}
                                  >
                                    <BsThreeDotsVertical
                                      id={`${typeOfRule || ""}${parentKey}`}
                                    />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>{children}</PopoverContent>
                              </Popover>
                            ) : (
                              <BsThreeDotsVertical
                                id={`${typeOfRule || ""}${parentKey}`}
                              />
                            )}
                          </div>
                        )}
                        {isToggleRequired && !isEditRequired && (
                          <div>
                            <MBOToggle
                              checkedState={toggleRow}
                              id={(value && Number(value.id)) || null}
                              setToggle={setToggle}
                              needText={true}
                              writeAccess={writeAccess}
                            />
                          </div>
                        )}
                        {isToggleRequired && isEditRequired && (
                          <div className={classes.settings_table}>
                            <MBOToggle
                              checkedState={toggleRow}
                              id={(value && Number(value.id)) || null}
                              needText={true}
                              setToggle={() => {
                                const LoggedInUser =
                                  authState?.idToken?.claims.email;

                                editToggleSwitchHandler &&
                                  editToggleSwitchHandler({
                                    updatedBy: LoggedInUser || "",
                                    settingsId: value.id?.toString() || "",
                                    isActive: !toggleRow[parentKey].checked,
                                  });
                              }}
                              writeAccess={writeAccess}
                              alterToggleSwitch={classes.alter_toggle_switch}
                              alterToggleSlider={classes.alter_toggle_slider}
                            />
                            <hr />
                            {isEstimatePayment && (
                              <a
                                className={[
                                  classes.estimate_payment,
                                  value.id && !toggleRow[parentKey]?.checked
                                    ? classes.disable_payment
                                    : "",
                                ].join(" ")}
                                onClick={() => {
                                  getEstimatePaymentRule &&
                                    getEstimatePaymentRule(value);
                                }}
                              >
                                Simulate/Edit Payment &gt;
                              </a>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        {!collapseTable && (
          <div className={classes.table_footer}>
            {tableData && tableData.length > 5 && (
              <span
                className={classes.see_all_results}
                onClick={() => setShowAlldata(!showAlldata)}
              >
                See all rules
                {!showAlldata ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
              </span>
            )}
            {showAddNewButton && (
              <MBOButton
                onClick={() => {
                  setForm && setForm(true);
                  getCLickPosition && getCLickPosition(null, typeOfRule || "");
                }}
                name={PrimaryButtonName}
                disabled={!writeAccess}
              />
            )}
          </div>
        )}
        {!collapseTable &&
          ((tableData && tableData.length === 0) || !tableData) && (
            <span className={classes.empty_error_page}>
              <FcDeleteDatabase className={classes.empty_data_icon} /> Uh Oh!
              Looks like{" "}
              {settingsStyle
                ? "Settings"
                : typeOfRule === "ruleset"
                ? "Rulesets"
                : "Rules"}{" "}
              are not available.
            </span>
          )}
      </div>
    </>
  );
};

export default ErrorBoundary(MBOTable);

import React from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { RiShareBoxLine } from "react-icons/ri";

import { selectedItemsProps } from "../../../../../../@types/component";
import MBOTooltip from "../../../../../../componentsPhase1/MBOTooltip";
import { readMoreLink } from "../../../../../../constants/helperText";
import classes from "../RatesTable/rates.module.scss";

interface CalculationProps {
  input: string;
  selectedTabIndex: number;
  sow: boolean;
  responseData: CalcAtributes["data"] | null;
  selectedFilters: selectedItemsProps;
  showPayRateBillRate: boolean;
}

const CalculationsTable: React.FC<CalculationProps> = ({
  input,
  selectedTabIndex,
  sow,
  responseData,
  selectedFilters,
  showPayRateBillRate,
}) => {
  const convertValue = (value: string | undefined, multiplier?: number) => {
    const result =
      value &&
      (value.replace("(", "").replace(")", "").replace(/,/g, "") || "");

    return multiplier && multiplier > 0
      ? `$${(Number(result?.replace("$", "")) * multiplier).toFixed(2)}`
      : result;
  };

  const taletPaidAttricutions = responseData?.attributions?.filter((e) =>
    e?.name?.toLowerCase()?.includes("talent paid")
  );

  return (
    <div className={classes.calculation}>
      <h2>
        <b>
          {" "}
          {sow && !showPayRateBillRate
            ? "SOW Calculation"
            : sow && showPayRateBillRate
            ? "Milestone Calculation"
            : "Calculated Rates"}
        </b>
      </h2>
      <div className={classes.content}>
        <div className={classes.row}>
          {sow && !showPayRateBillRate && (
            <>
              <span>Pay rate</span>
              <span>{convertValue(responseData?.payRate?.value) || ""}</span>
            </>
          )}
          {((sow && showPayRateBillRate) || (!sow && !showPayRateBillRate)) && (
            <>
              {selectedTabIndex === 0 ? (
                <>
                  <span>Pay rate</span>
                  <span>
                    {convertValue(responseData?.payRate?.value) || ""}
                  </span>
                </>
              ) : (
                <>
                  <span>Bill rate</span>
                  <span>
                    {convertValue(responseData?.billRate?.value) || ""}
                  </span>
                </>
              )}
            </>
          )}
        </div>
        {selectedTabIndex === 0 &&
          taletPaidAttricutions &&
          taletPaidAttricutions?.length > 0 &&
          taletPaidAttricutions?.map((item, index) => {
            return (
              <div className={classes.row} key={index}>
                <div className={classes.info_icon}>
                  <span>{item.name}</span>
                  <div className={classes.container_tip}>
                    <MBOTooltip arrowBottom bottom={classes.visible_tip}>
                      <div className={classes.tooltip_container}>
                        <header>{item.name}</header>
                        <main>{item.description}</main>
                        {readMoreLink && (
                          <a
                            href={readMoreLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                            <RiShareBoxLine />
                          </a>
                        )}
                      </div>
                    </MBOTooltip>
                    <IoMdInformationCircle fontSize={16} fill="#352281" />
                  </div>
                </div>
                <div className={classes.row_split}>
                  <span>{`(${item.comment || ""})`}</span>
                  <span>{convertValue(item.rate.value)}</span>
                </div>
              </div>
            );
          })}
        {(sow && !showPayRateBillRate) ||
        (sow && showPayRateBillRate && selectedTabIndex === 0) ||
        (!sow && !showPayRateBillRate && selectedTabIndex === 0) ? (
          <div className={classes.row}>
            <span>Net Pay rate</span>
            <span>{convertValue(responseData?.netPayRate?.value) || ""}</span>
          </div>
        ) : null}
        {responseData &&
          responseData.attributions.map((item, index) => {
            return item?.name?.toLowerCase()?.includes("talent paid") ? null : (
              <div className={classes.row} key={index}>
                <div className={classes.info_icon}>
                  <span>{item.name}</span>
                  <div className={classes.container_tip}>
                    <MBOTooltip arrowBottom bottom={classes.visible_tip}>
                      <div className={classes.tooltip_container}>
                        <header>{item.name}</header>
                        <main>{item.description}</main>
                        {readMoreLink && (
                          <a
                            href={readMoreLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                            <RiShareBoxLine />
                          </a>
                        )}
                      </div>
                    </MBOTooltip>
                    <IoMdInformationCircle fontSize={16} fill="#352281" />
                  </div>
                </div>
                <div className={classes.row_split}>
                  <span>{`(${item.comment || ""})`}</span>
                  <span>{convertValue(item.rate.value)}</span>
                </div>
              </div>
            );
          })}
        {sow && !showPayRateBillRate && (
          <div className={classes.row}>
            <span>Bill rate</span>
            <span>{convertValue(responseData?.billRate.value)}</span>
          </div>
        )}
        {sow && !showPayRateBillRate && (
          <div className={classes.final}>
            <>
              <span>SOW Markup</span>
              <span>{responseData?.markup?.value}</span>
            </>
          </div>
        )}
        {(!sow || showPayRateBillRate) && (
          <div className={classes.final}>
            {selectedTabIndex === 0 ? (
              <>
                <span>Bill rate</span>
                <span>{convertValue(responseData?.billRate?.value)}</span>
              </>
            ) : (
              <>
                <span>Pay rate</span>
                <span>{convertValue(responseData?.payRate?.value)}</span>
              </>
            )}
          </div>
        )}
        {selectedTabIndex === 1 &&
          taletPaidAttricutions &&
          taletPaidAttricutions?.length > 0 &&
          taletPaidAttricutions?.map((item, index) => {
            return (
              <div className={classes.row} key={index}>
                <div className={classes.info_icon}>
                  <span>{item.name}</span>
                  <div className={classes.container_tip}>
                    <MBOTooltip arrowBottom bottom={classes.visible_tip}>
                      <div className={classes.tooltip_container}>
                        <header>{item.name}</header>
                        <main>{item.description}</main>
                        {readMoreLink && (
                          <a
                            href={readMoreLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Read more
                            <RiShareBoxLine />
                          </a>
                        )}
                      </div>
                    </MBOTooltip>
                    <IoMdInformationCircle fontSize={16} fill="#352281" />
                  </div>
                </div>
                <div className={classes.row_split}>
                  <span>{`(${item.comment || ""})`}</span>
                  <span>{convertValue(item.rate.value)}</span>
                </div>
              </div>
            );
          })}

        {selectedTabIndex === 1 && !sow && (
          <div className={classes.row}>
            <span>Net Pay rate</span>
            <span>{convertValue(responseData?.netPayRate?.value) || ""}</span>
          </div>
        )}
        {(!sow && selectedFilters.engagementType.name === "Payroll Services") ||
        showPayRateBillRate ? (
          selectedTabIndex === 0 ? (
            <>
              <div className={classes.row}>
                <span>Overtime Bill rate</span>
                <span>({responseData?.standardOtRatio}x)</span>

                <span>
                  {convertValue(
                    responseData?.billRate.value,
                    responseData?.standardOtRatio
                  )}
                </span>
              </div>
              <div className={classes.row}>
                <span>Doubletime Bill rate</span>
                <span>({responseData?.standardDtRatio}x)</span>

                <span>
                  {convertValue(
                    responseData?.billRate.value,
                    responseData?.standardDtRatio
                  )}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className={classes.row}>
                <span>OverTime Pay rate</span>
                <span>({responseData?.standardOtRatio}x)</span>

                <span>
                  {convertValue(
                    responseData?.payRate.value,
                    responseData?.standardOtRatio
                  )}
                </span>
              </div>
              <div className={classes.row}>
                <span>DoubleTime Pay rate</span>
                <span>({responseData?.standardDtRatio}x)</span>

                <span>
                  {convertValue(
                    responseData?.payRate.value,
                    responseData?.standardDtRatio
                  )}
                </span>
              </div>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CalculationsTable;

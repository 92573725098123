import moment from "moment";
import { useErrorBoundary } from "react-error-boundary";

import {
  manageOptionsRuleForClientProp,
  stateIprops,
} from "../../../../../@types/component";
import ErrorBoundaryFallBackComponent from "../../../../../HOC/ErrorBoundary/errorBoundary.component";
import MBOTable from "../../../../../componentsPhase1/MBOTable";

const OptionalRules: React.FC<manageOptionsRuleForClientProp> = ({
  rule,
  getCLickPosition,
  setForm,
  getDeletedId,
  setDeleteForm,
  clearPopOver,
  showAddNewButton,
  children,
}) => {
  const data: stateIprops[] = [];
  const { showBoundary } = useErrorBoundary();

  try {
    const optionalRules =
      (rule &&
        rule.data &&
        rule.data.optionalRules &&
        rule.data.optionalRules.map((rule) => {
          return {
            ...rule,
            amount:
              rule.amount.localized === "percentage"
                ? `${Number(rule.amount.raw)}%`
                : `$${Number(rule.amount.raw) / 100}`,
          };
        })) ||
      [];

    optionalRules.forEach((rule) => {
      data.push({
        id: Number(rule?.id),
        clientId: Number(rule?.client?.id),
        name: rule.name,
        description: rule.description,
        effectiveDate:
          (rule.effectiveDate &&
            rule.effectiveDate.raw &&
            moment(rule.effectiveDate.value, "MMM DD YYYY")) ||
          null,
        expireDate:
          (rule.expireDate &&
            rule.expireDate.raw &&
            moment(rule.expireDate.value, "MMM DD YYYY")) ||
          null,
        operation: {
          label: rule.operation.name,
          value: Number(rule.operation.id),
        },

        engagementType: rule.engagementType || "",
        amount: rule.amount,
        performedBy: rule.performedBy,

        attribution: {
          label: rule.attribution.name,
          value: Number(rule.attribution.id),
        },
        group: {
          label: rule.group.name,
          value: Number(rule.group.id),
          isAvailableToMarkdown: rule.group.isAvailableToMarkdown,
        },
        vendor:
          (rule &&
            rule.vendor && {
              label: rule.vendor.vendorName,
              value: Number(rule.vendor.id),
            }) ||
          null,
        createdDate:
          rule.createdDate && rule.createdDate.raw
            ? moment(rule.createdDate.raw).local()
            : null,
        updatedDate:
          rule.updatedDate && rule.updatedDate.raw
            ? moment(rule.updatedDate.raw).local()
            : null,
      });
    });
  } catch (e) {
    showBoundary("no rules are available");
  }

  return (
    <MBOTable
      rule={data}
      headerName="Optional Rules"
      showAddNewButton={showAddNewButton || false}
      typeOfRule={"optional"}
      isEditRequired={true}
      isToggleRequired={false}
      getCLickPosition={getCLickPosition}
      setForm={setForm}
      setDeleteForm={setDeleteForm}
      getDeletedId={getDeletedId}
      clearPopOver={clearPopOver}
    >
      {children}
    </MBOTable>
  );
};

export default ErrorBoundaryFallBackComponent(OptionalRules);

import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";

import "../../App.css";

import classes from "./index.module.scss";

interface Option {
  value: number;
  label: string;
}

interface AutocompleteDropdownProps {
  options: Option[];
  onSelect: (selectedOption: Option) => void;
  placeholder: string;
  alterStyle?: string;
  type?: string;
  value: Option[];
  showRequiredFieldMessage?: boolean;
  disable?: boolean;
  errorMesssageWidth?: string;
  errorMessage?: string;
}

const AutocompleteDropdown: React.FC<AutocompleteDropdownProps> = ({
  options,
  onSelect,
  placeholder,
  alterStyle,
  value,
  showRequiredFieldMessage = false,
  disable = false,
  errorMessage = "",
  errorMesssageWidth = "",
}) => {
  const [inputValue, setInputValue] = useState<string>(
    (value && value.length > 0 && value[0].label !== "" && value[0].label) || ""
  );

  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disable) {
      return false;
    }

    const { value } = e.target;

    setIsOpen(true);

    setInputValue(value);
    filterOptions(value);

    const option = options.find((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    ) as Option;

    onSelect(
      value === ""
        ? { value: 0, label: "" }
        : (!option && { label: value, value: 0 }) || { ...option }
    );
  };

  const filterOptions = (value: string) => {
    const filtered =
      options &&
      options.length > 0 &&
      options.filter((option) =>
        value === ""
          ? true
          : option.label.toLowerCase().includes(value.toLowerCase())
      );

    setFilteredOptions(filtered || []);
  };

  const handleOptionClick = (option: Option) => {
    setInputValue(option.label);
    setIsOpen(false);
    onSelect(option);
  };

  const toggleDropdown = () => {
    if (disable) {
      setIsOpen(false);

      return;
    }

    if (!isOpen) {
      filterOptions("");
    }

    setIsOpen(!isOpen);
  };

  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (focusedIndex >= 0 && listRef.current) {
      const children = listRef.current
        .children as HTMLCollectionOf<HTMLElement>;

      children[focusedIndex]?.focus();
    }
  }, [focusedIndex]);

  const [focusedElement, setFocusedElement] = useState<HTMLElement | null>(
    null
  );

  const handleKeyPress = (e: KeyboardEvent<HTMLUListElement>) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex &&
        setFocusedIndex((prevIndex) =>
          prevIndex > 0
            ? prevIndex - 1
            : (listRef.current?.children.length ?? 0) - 1
        );
    } else if (e.key === "ArrowDown" && listRef.current) {
      if (focusedIndex === -1) {
        setFocusedIndex && setFocusedIndex(0);

        return;
      }

      e.preventDefault();
      setFocusedIndex &&
        setFocusedIndex((prevIndex) =>
          prevIndex === (listRef.current?.children.length ?? 0) - 1
            ? 0
            : prevIndex + 1
        );

      if (
        focusedIndex &&
        focusedIndex >= 0 &&
        listRef.current &&
        focusedElement
      ) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    } else if (e.key === "Enter" && Boolean(focusedIndex !== -1)) {
      setInputValue(filteredOptions[focusedIndex || 0].label);
      setIsOpen(false);
      onSelect(filteredOptions[focusedIndex]);

      setFocusedIndex && setFocusedIndex(-1);
    }
  };

  useEffect(() => {
    // Set the focused element when the index changes

    if (listRef.current) {
      const elements = listRef.current.querySelectorAll(".option");

      if (
        elements.length > 0 &&
        focusedIndex !== undefined &&
        focusedIndex !== null &&
        focusedIndex > -1
      ) {
        const element = elements[focusedIndex] as HTMLLIElement;

        setFocusedElement(element);
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [focusedIndex, listRef.current?.children]);

  useEffect(() => {
    try {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } catch (e) {
      // showBoundary("Something went wrong, please try again later");
    }
  }, []);

  const handleItemClick = (index: number) => {
    setFocusedIndex(index);
  };

  useEffect(() => {
    if (!isOpen) {
      setFocusedIndex(-1);
    }
  }, [isOpen]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      // Show or focus the suggestion window when down arrow is pressed
      setIsOpen(true);
      // Focus the suggestion window for keyboard navigation
      // Use refs or some other mechanism to focus on the suggestion window
      listRef.current?.focus();
      setFocusedIndex(0);
    }
  };

  return (
    <div
      className={[
        classes.autocomplete_dropdown,

        alterStyle && alterStyle !== "" ? alterStyle : "",
        inputValue && inputValue !== ""
          ? classes.selected_fill_selected
          : classes.selected_fill,
        disable ? classes.disable_component : "",
      ].join(" ")}
      ref={dropdownRef}
      onClick={toggleDropdown}
      data-testid="form-drp"
    >
      {inputValue && inputValue !== "" && (
        <span
          className={[
            classes.label_with_data,
            inputValue && inputValue !== ""
              ? classes.selected_fill_selected_input
              : "",
          ].join(" ")}
        >
          {placeholder}
        </span>
      )}
      <div>
        <input
          type="text"
          data-testid="inputparent"
          className={[
            classes.formInput,
            inputValue && inputValue !== ""
              ? classes.selected_fill_selected_input
              : "",
            disable ? classes.disable_component : "",
          ].join(" ")}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          autoComplete="off"
          onKeyDown={handleKeyDown}
        />
        {(isOpen && (
          <SlArrowUp
            className={[
              classes.dropdown_arrow,
              inputValue && inputValue !== ""
                ? classes.selected_fill_selected_input
                : "",
            ].join(" ")}
          />
        )) || (
          <SlArrowDown
            className={[
              classes.dropdown_arrow,
              inputValue && inputValue !== ""
                ? classes.selected_fill_selected_input
                : "",
            ].join(" ")}
          />
        )}
      </div>
      {isOpen && (
        <ul
          className={classes.options}
          onMouseLeave={() => setIsOpen(false)}
          ref={listRef}
          onKeyDown={handleKeyPress}
        >
          {filteredOptions.map((option, i) => (
            <li
              tabIndex={focusedIndex === -1 ? 0 : focusedIndex}
              className={[
                classes.form_list_li,
                `option ${
                  focusedIndex === i ? "dropdown_options_keyboard_selected" : ""
                }`,
              ].join(" ")}
              onFocus={() => handleItemClick(i)}
              key={option.value}
              onClick={() => handleOptionClick(option)}
              ref={(element) => {
                if (i === focusedIndex && element) {
                  setFocusedElement(element);
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  });
                }
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {((inputValue === "" && showRequiredFieldMessage) ||
        (showRequiredFieldMessage && errorMessage)) && (
        <span
          className={`${classes.mandatory} ${
            classes.error_messsage_fullwidth
          }  ${errorMessage.length > 55 ? errorMesssageWidth : ""}`}
        >
          {`! ${errorMessage !== "" ? errorMessage : "Required"}`}
        </span>
      )}
      {(inputValue &&
        inputValue !== "" &&
        options &&
        options.length > 0 &&
        options.findIndex((e) =>
          e.label.toLowerCase().includes(inputValue.toLowerCase())
        ) < 0 && (
          <span className={classes.mandatory}>
            ! Select option from the list
          </span>
        )) ||
        ""}
    </div>
  );
};

export default AutocompleteDropdown;

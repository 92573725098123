const ManageRuleDataModel = [
  {
    id: "",
    client: {
      id: "",
      name: "",
    },
    name: "",
    description: "",
    effectiveDate: null,
    expireDate: null,
    country: null,
    mainDivision: null,
    city: null,
    rateLow: null,
    rateHigh: null,
    engagementType: "",
    attribution: {
      id: "" || null,
      name: "" || null,
    },
    group: {
      id: "" || null,
      name: "" || null,
      isAvailableToMarkdown: false,
    },
    operation: {
      id: "" || null,
      name: "" || null,
    },
    amount: {
      localized: "",
      culture: "",
      value: "",
      raw: "",
    },
    currency: "USD",
    vendor: {
      id: "" || null,
      vendorName: "" || null,
    },
    createdDate: {
      localized: "",
      value: "",
      culture: "",
      timeZone: "",
      raw: null,
    },
    updatedDate: {
      localized: "",
      value: "",
      culture: "",
      timeZone: "",
      raw: null,
    },
    performedBy: "",
  },
];

export const ManageStandardRulesDataModel = [
  {
    id: "",
    client: {
      id: "",
      name: "",
    },
    name: "",
    description: "",
    effectiveDate: null,
    expireDate: null,
    country: "",
    mainDivision: "",
    city: "",
    rateLow: "",
    rateHigh: "",
    engagementType: "",
    group: {
      id: "" || null,
      name: "" || null,
      isAvailableToMarkdown: false,
    },
    operation: {
      id: "" || null,
      name: "" || null,
    },
    attribution: {
      id: "" || null,
      name: "" || null,
    },
    amount: {
      localized: "",
      culture: "",
      value: "",
      raw: "",
    },
    vendor: {
      id: "" || null,
      vendorName: "" || null,
    },
    currency: "USD",

    performedBy: "",
    createdDate: null,
    updatedDate: null,

    ruleType: "STANDARD",
    engagementTypeName: "",
    engagementTypeId: "",
  },
];

export default ManageRuleDataModel;
